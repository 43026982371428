import React, {
    Component
} from 'react';
import ReactDOM from "react-dom";

import {
    ReactSVG
} from 'react-svg';

import walkScoreImg from '../../img/transitscreen/badges/walk-score.svg';
import mobilityScoreImg from '../../img/transitscreen/badges/mobility-score.svg';
import tasBronzeImg from '../../img/transitscreen/badges/tas-bronze.svg';
import tasGoldImg from '../../img/transitscreen/badges/tas-gold.svg';
import tasPlatinumImg from '../../img/transitscreen/badges/tas-platinum.svg';
import tasSilverImg from '../../img/transitscreen/badges/tas-silver.svg';
import transitScore from '../../img/transitscreen/badges/transit-score.svg';
import leedBasic from '../../img/transitscreen/badges/leed-basic.svg';
import leedSilver from '../../img/transitscreen/badges/leed-silver.svg';
import leedGold from '../../img/transitscreen/badges/leed-gold.svg';
import leedPlatinum from '../../img/transitscreen/badges/leed-platinum.svg';
import energyStar from '../../img/transitscreen/badges/energy-star.svg';
import leedCanadaBasic from '../../img/transitscreen/badges/leed-ca-basic.svg';
import leedCanadaSilver from '../../img/transitscreen/badges/leed-ca-silver.svg';
import leedCanadaGold from '../../img/transitscreen/badges/leed-ca-gold.svg';
import leedCanadaPlatinum from '../../img/transitscreen/badges/leed-ca-platinum.svg';
import boma360 from '../../img/transitscreen/badges/boma-360.svg';
import bomaBasic from '../../img/transitscreen/badges/boma-basic.svg';
import bomaBronze from '../../img/transitscreen/badges/boma-bronze.svg';
import bomaGold from '../../img/transitscreen/badges/boma-gold.svg';
import bomaSilver from '../../img/transitscreen/badges/boma-silver.svg';
import bomaPlatinum from '../../img/transitscreen/badges/boma-platinum.svg';
import breeam from '../../img/transitscreen/badges/breeam.svg';
// import iconLogo from '../../img/';




class Logo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const logoImg = {
            "walk-score": walkScoreImg,
            "mobility-score": mobilityScoreImg,
            "tas-bronze": tasBronzeImg,
            "tas-gold": tasGoldImg,
            "tas-platinum": tasPlatinumImg,
            "tas-silver": tasSilverImg,
            "transit-score": transitScore,
            "leed-basic": leedBasic,
            "leed-silver": leedSilver,
            "leed-gold": leedGold,
            "leed-platinum": leedPlatinum,
            "energy-star": energyStar,
            "leed-ca-basic": leedCanadaBasic,
            "leed-ca-silver": leedCanadaSilver,
            "leed-ca-gold": leedCanadaGold,
            "leed-ca-platinum": leedCanadaPlatinum,
            "boma-360": boma360,
            "boma-basic": bomaBasic,
            "boma-bronze": bomaBronze,
            "boma-gold": bomaGold,
            "boma-silver": bomaSilver,
            "boma-platinum": bomaPlatinum,
            "breeam": breeam
        };
        const logoSrc = this.props.blockData.client_logo_url;

        var badge1 = this.props.blockData.badge_1;
        if (this.props.blockData.badge_1 !== "") {
            badge1 = badge1.replace('/svg/badges/', '').replace('.svg', '');
        }

        var badge2 = this.props.blockData.badge_2;
        if (this.props.blockData.badge_2 !== "") {
            badge2 = badge2.replace('/svg/badges/', '').replace('.svg', '');
        }

        return (
            <div className={this.props.cssClasses}>
                {this.props.blockData.client_logo_url !== "" ? <img className="customer-logo" alt="Logo" src={logoSrc} ref="logoImg"></img> : null}

                {
                    badge1 || badge2 ?
                    <div className="badges">
                    {
                        badge1 ?
                        <div className={badge1}><ReactSVG src={logoImg[badge1]} wrapper="div" className="badge" />
                            {
                                this.props.blockData.badge_1_score_number ?
                                <span className="number">{this.props.blockData.badge_1_score_number}</span>
                                : null
                            }
                        </div>
                        : null
                    }
                    {
                        badge2 ?
                        <div className={badge2}><ReactSVG src={logoImg[badge2]} wrapper="div" className="badge" />
                            {
                                this.props.blockData.badge_2_score_number ?
                                <span className="number">{this.props.blockData.badge_2_score_number}</span>
                                : null
                            }
                        </div>
                        : null
                    }
                    </div>
                : null
                }
            </div>
        );
    }
}

Logo.displayName = "Logo";
export default Logo;
