import React, {
    Component
} from 'react';

import Moment from 'react-moment'; // https://momentjs.com/
import 'moment-timezone';

import {
    Carousel
} from 'react-responsive-carousel';
import {
    ReactSVG
} from 'react-svg';

const logoDictionary = {
    // Music
    'Concert': '1019-music-note-2-toolbar@2x',
    'Rock': '1000-guitar-toolbar@2x',
    'Country': '1000-guitar-toolbar@2x',
    'Pop': '967-piano-toolbar@2x',
    'Hip-Hop/Rap': '1019-music-note-2-toolbar@2x',
    'Reggae': '1019-music-note-2-toolbar@2x',
    'Music': '1019-music-note-2-toolbar@2x',
    'R&B': '1019-music-note-2-toolbar@2x',
    'Dance/Electronic': '1019-music-note-2-toolbar@2x',
    'Metal': '1000-guitar-toolbar@2x',
    'World': '1019-music-note-2-toolbar@2x',

    // Arts & Theatre
    'Arts & Theatre': '1044-drama-masks-toolbar@2x',
    'Comedy': '1044-drama-masks-toolbar@2x',
    'Theatre': '1044-drama-masks-toolbar@2x',
    'Children\'s Theatre': '1044-drama-masks-toolbar@2x',
    'Dance': '1033-dance-steps-toolbar@2x',
    'Magic': '1044-drama-masks-toolbar@2x',
    'Magic & Illusion': '1044-drama-masks-toolbar@2x',

    // Sports
    'Basketball': '1086-basketball-toolbar@2x',
    'Hockey': '1158-hockey-stick-and-puck-toolbar@2x',
    'Football': '1085-football-toolbar@2x',
    'Soccer': '1087-soccer-ball-toolbar@2x',
    'Motorsports/Racing': '917-speedometer-toolbar@2x',
    'Martial Arts': '1092-boxing-glove-toolbar@2x',
    'Boxing': '1092-boxing-glove-toolbar@2x',
    'Wrestling': '1092-boxing-glove-toolbar@2x',
    'Rugby': '1085-football-toolbar@2x',
    'Baseball': '1084-baseball-toolbar@2x',

    'default': 'general'
}

const EventRow = (props) => {
    return (
        <div className="content-container__row local-events__row">
            <span className="local-events__row__icon">
                <ReactSVG className="visual-icon-fill" src={"/img/categoryicons/" + logoDictionary['default'] + ".svg"} />
            </span>
            <span className="local-events__row__name">
                <span>{props.event.name}</span>
                {props.event.startTime ? <span className="local-events__row__name__start-time"><Moment locale={props.localization.currentLanguage.substring(0, 2)} calendar>{props.event.startTime}</Moment></span> : ""}
            </span>
            <span className="venue">
                {props.event.venue}
            </span>
        </div>
    );
}

class LocalEvents extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let eventsArr = [];

        if (this.props.blockData.row_limit === null) {
            eventsArr.push(this.props.blockData.events);
        } else {
            // assuming every slide will have the same number of elements
            eventsArr = Array.from({
                length: Math.ceil(this.props.blockData.events.length / this.props.blockData.row_limit)
            }, (v, i) =>
                this.props.blockData.events.slice(i * this.props.blockData.row_limit, i * this.props.blockData.row_limit + this.props.blockData.row_limit)
            );
        }

        return (
            <div className={this.props.cssClasses}>

                <h2 className="content-container__header local-events__header">{this.props.blockData.title || this.props.localization.translations[this.props.localization.currentLanguage]["ts1f"]}</h2>
                {
                    eventsArr.length ?
                        <div className="slide-wrap">
                            <Carousel autoPlay infiniteLoop interval={10000} showThumbs={false}>
                                {
                                    eventsArr.map((eventsPage, index) =>
                                        <div key={index} className="slide">
                                            {
                                                eventsPage.map((row, index) =>
                                                    <EventRow key={index} event={row} localization={this.props.localization} />
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </Carousel>
                        </div>
                        :
                        ""
                }

            </div>
        );
    }
}

Carousel.displayName = "Carousel";
LocalEvents.displayName = "LocalEvents";
export default LocalEvents;
